import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './header.scss';
import Hamburger from './Hamburger';
import { ReactComponent as BagOfTricksImage } from '../../../assets/images/header-icons/tricks.svg';
import { ReactComponent as ProfileImage } from '../../../assets/images/header-icons/profile.svg';
import { useHeader } from './headerContext';
import Notifications from './Notifications';
function Header() {
  const ref = useRef();
  const { jiggle } = useHeader();
  const [show, showBagOfTricksCards] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the dropdown is open and the clicked target is not within the menu,
      // then close the menu

      if (show && ref.current && !ref.current.contains(e.target)) {
        showBagOfTricksCards(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  return (
    <>
      <div className='header-wrap'>
        <a href='/course/overview'>
          <div className='logo-wrap'>
            <div className='item-title'></div>
          </div>
        </a>
        <div className='icons-box' ref={ref}>
          <Notifications />
          <NavLink
            to={'/bag-of-tricks-page'}
            activeClassName='active'
            className={jiggle && 'jiggle'}
          >
            <BagOfTricksImage />
          </NavLink>
          <NavLink to={'/profile'} activeClassName='active'>
            <div className='icon profile'>
              <ProfileImage />
            </div>
          </NavLink>
        </div>
        <Hamburger />
      </div>
    </>
  );
}

export default Header;
