import React, { useEffect, useState } from 'react';
import '../handouts/handouts.scss';
import allHandouts from './allHandouts';
import { ReactComponent as Icon } from '../../../assets/images/PDFs.svg';
function Handout(props) {
  let handOut = props.content;
  const PdftoRender = allHandouts[handOut];

  const [content, setContent] = useState(null);
  useEffect(() => {
    setContent({ __html: props.data });
  }, [props.data]);

  return (
    <>
      <div className='handout-wrap'>
        {/* {content ? <div dangerouslySetInnerHTML={content}></div> : <></>} */}
        {/* {content ? (
          <div>
            <h2>Click to review handout:</h2>
            <b> </b>
            <a href={props.content} target="_blank" rel="noreferrer">
              {props.content}
            </a>
          </div>
        ) : (
          <></>
        )} */}
        {content ? (
          <a
            href={PdftoRender}
            className='handout-button'
            target='_blank'
            rel='noreferrer'
          >
            <p>Review Handout</p>
            <Icon />
          </a>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Handout;
