import m1Pdf1 from "../../assets/handouts/ho11.pdf";
import m2Pdf1 from "../../assets/handouts/ho21.pdf";
import m2Pdf2 from "../../assets/handouts/ho22.pdf";
import m2Pdf3 from "../../assets/handouts/ho23.pdf";
import m2Pdf4 from "../../assets/handouts/ho24.pdf";
import m3Pdf1 from "../../assets/handouts/ho31.pdf";
import m3Pdf2 from "../../assets/handouts/ho32.pdf";
import m4Pdf1 from "../../assets/handouts/ho41.pdf";
import m4Pdf2 from "../../assets/handouts/ho42.pdf";
import m5Pdf1 from "../../assets/handouts/ho51.pdf";
import m5Pdf2 from "../../assets/handouts/ho52.pdf";
import m5Pdf3 from "../../assets/handouts/ho53.pdf";
import m6Pdf1 from "../../assets/handouts/ho61.pdf";
import m6Pdf2 from "../../assets/handouts/ho62.pdf";
import m6Pdf3 from "../../assets/handouts/ho63.pdf";
const mod1 = [{ target: m1Pdf1, name: "Summary of Important Points" }];
const mod2 = [
  {
    target: m2Pdf1,
    name: "Examples of Ways to Give Praise and Encouragement to Children",
  },
  {
    target: m2Pdf2,
    name: `Examples of Praise & Encouragement to Give to Your Child’s
Teacher, Caregiver, or Family Member`,
  },
  { target: m2Pdf3, name: "How to Begin & End an Effective Reward Program" },
  { target: m2Pdf4, name: "Summary of Important Points" },
];
const mod3 = [
  {
    target: m3Pdf1,
    name: "Say What You Mean & Mean What You Say",
  },
  {
    target: m3Pdf2,
    name: "Summary of Important Points",
  },
];
const mod4 = [
  {
    target: m4Pdf2,
    name: "5 Common Questions About Ignoring",
  },
  {
    target: m4Pdf1,
    name: "Summary of Important Points",
  },
];
const mod5 = [
  {
    target: m5Pdf1,
    name: "8 Keys to Effective Discipline",
  },
  {
    target: m5Pdf2,
    name: "Steps to an Effective Time-out",
  },
  {
    target: m5Pdf3,
    name: "Summary of Important Points",
  },
];
const mod6 = [
  {
    target: m6Pdf1,
    name: "Take 5 Breathing Exercise",
  },
  {
    target: m6Pdf2,
    name: "Recipe for Making Mind Jars",
  },
  {
    target: m6Pdf3,
    name: "Summary of Important Points",
  },
];

export { mod1, mod2, mod3, mod4, mod5, mod6 };
