import React, { useEffect } from 'react';
import './video-player.scss';
import Vimeo from '@u-wave/react-vimeo';
// import ReactPlayer from "react-player"

function VideoPlayer(props) {
  useEffect(() => {
    if (props.setVideoWatched) {
      props.setVideoWatched(false);
    }
  }, []);

  return (
    <>
      <Vimeo
        video={props.source}
        controls={true}
        showTitle={true}
        showPortrait={false}
        onEnd={() => {
          if (props.setVideoWatched) {
            props.setVideoWatched(true);
          }
        }}
      />
    </>
  );
}

export default VideoPlayer;
