let allHandouts = {};

allHandouts["handout11"] =
  require("../../../assets/handouts/ho11.pdf").default;
allHandouts["handout21"] =
  require("../../../assets/handouts/ho21.pdf").default;
allHandouts["handout22"] =
  require("../../../assets/handouts/ho22.pdf").default;
allHandouts["handout23"] =
  require("../../../assets/handouts/ho23.pdf").default;
allHandouts["handout24"] =
  require("../../../assets/handouts/ho24.pdf").default;
allHandouts["handout31"] =
  require("../../../assets/handouts/ho31.pdf").default;
allHandouts["handout32"] =
  require("../../../assets/handouts/ho32.pdf").default;
allHandouts["handout41"] =
  require("../../../assets/handouts/ho41.pdf").default;
allHandouts["handout42"] =
  require("../../../assets/handouts/ho42.pdf").default;
allHandouts["handout51"] =
  require("../../../assets/handouts/ho51.pdf").default;
allHandouts["handout52"] =
  require("../../../assets/handouts/ho52.pdf").default;
allHandouts["handout53"] =
  require("../../../assets/handouts/ho53.pdf").default;
allHandouts["handout61"] =
  require("../../../assets/handouts/ho61.pdf").default;
allHandouts["handout62"] =
  require("../../../assets/handouts/ho62.pdf").default;
allHandouts["handout63"] =
  require("../../../assets/handouts/ho63.pdf").default;

export default allHandouts;
