import React from 'react';
import GenStyles from '../../../GenStyles';
import styled from 'styled-components';
import allBadges from '../../../../shared/badges/allBadges';
import hekbadges from '../../../assets/hekallBadges';
const S = {
  BadgeImg: styled.img`
    height: 60px;
    width: 60px;
    margin: 0 10px;
  `,
  BadgeWrapper: styled.div`
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
    min-width: 90vw;
    margin-bottom: 0.5rem;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
      min-width: 0;
    }
  `,
  BadgeDateWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  `,
  DateText: styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: black;
    border-bottom: black solid 2px;
    margin-left: ${(props) => (props.badge ? '0' : '1.25rem')};
    margin-top: 0;
    color: ${(props) => (props.badge ? 'black' : '#5f2c86')};
  `,
};

const BadgeDisplay = ({ badge, complete, program }) => {
  return (
    <S.BadgeDateWrapper>
      <S.BadgeImg
        className={complete ? '' : 'greyed-out'}
        src={
          program === 'EZP'
            ? require(`../../../../../assets/badges/${badge.badgeName}.png`)
                .default
            : require(`../../../assets/badges/${badge.badgeName}.png`).default
        }
        alt={badge.badgeName}
      />
      {badge.date && <S.DateText badge>{badge.date}</S.DateText>}
    </S.BadgeDateWrapper>
    // </a>
  );
};

const Badges = ({ completedBadges, program }) => {
  const badgeArray = program === 'EZP' ? allBadges : hekbadges;
  const badges = badgeArray.map((item) => {
    const completedItem = completedBadges.find(
      (modItem) => item.badgeName === modItem.badgename
    );
    return completedItem
      ? { ...item, date: completedItem.date }
      : { ...item, date: undefined };
  });

  const sortBadges = (module) => {
    return {
      header: module,
      items: badges
        .filter((item) => item.module === module)
        .map((item) => (
          <BadgeDisplay badge={item} complete={item.date} program={program} />
        )),
    };
  };

  const badgeTypes = [1, 2, 3, 4, 5, 6].map((item) => sortBadges(item));
  return (
    <GenStyles.UserItemWrapper>
      <GenStyles.InfoHeader>Badges</GenStyles.InfoHeader>
      {badgeTypes.map((type) => {
        return (
          <div>
            <GenStyles.Header3>{'Module ' + type.header}</GenStyles.Header3>
            <S.BadgeWrapper>{type.items}</S.BadgeWrapper>
          </div>
        );
      })}
    </GenStyles.UserItemWrapper>
  );
};

export default Badges;
