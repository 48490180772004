import React, { useEffect, useState } from 'react';
import '.././PlanIt.scss';
import Question from '../Question';
import TextInput from '../../Form/TextInput';
import { CheckList } from '../../Form/CheckList';
import { useForm } from '../../../customHooks/useForm';
import { handleSubmit } from '../../core/helperFuncs/handleSubmit';
import m2Pdf3 from '../../../assets/handouts/ho22.pdf';
import { handlePracticeSubmit } from '../../core/helperFuncs/handlePracticeSubmit';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
export default function BehaviorAgain({ content }) {
  const [state, handleChange, setState] = useForm();
  const [res, setRes] = useGetData('top-5');

  useEffect(() => {
    if (res.data) {
      setState({
        item1: res.data[0] ? res.data[0] : '',
        item2: res.data[1] ? res.data[1] : '',
        item3: res.data[2] ? res.data[2] : '',
        item4: res.data[3] ? res.data[3] : '',
        item5: res.data[4] ? res.data[4] : '',
      });
    }
  }, [res]);
  return (
    <>
      <div className='padding-1'>
        <Question>
          1. Make a list of 3-5 behaviors you want to see more often in your
          child. Try to say what you do want rather than what you don’t want.
          For example, instead of writing “not leave his toys out,” write “put
          his toys away.”
        </Question>
      </div>
      <form
        id='top-5'
        className='question-wrapper'
        onSubmit={(e) => {
          e.preventDefault();
          handlePracticeSubmit(e.target.id, Object.values(state));
        }}
      >
        <ol>
          <li className='add-items'>
            <TextInput
              handleChange={handleChange}
              state={state['item1']}
              name={'item1'}
              type='text'
              initial={res.data[0] ? res.data[0] : false}
            />
          </li>
          <li className='add-items'>
            <TextInput
              handleChange={handleChange}
              state={state['item2']}
              name={'item2'}
              type='text'
              initial={res.data[1] ? res.data[1] : false}
            />
          </li>
          <li className='add-items'>
            <TextInput
              handleChange={handleChange}
              state={state['item3']}
              name={'item3'}
              type='text'
              initial={res.data[2] ? res.data[2] : false}
            />
          </li>
          <li className='add-items'>
            <TextInput
              handleChange={handleChange}
              state={state['item4']}
              name={'item4'}
              type='text'
              initial={res.data[3] ? res.data[3] : false}
            />
          </li>
          <li className='add-items'>
            <TextInput
              handleChange={handleChange}
              state={state['item5']}
              name={'item5'}
              type='text'
              initial={res.data[4] ? res.data[4] : false}
            />
          </li>
        </ol>
      </form>
      <p className='padding-1'>
        2. Praise another adult (for example, your partner, child’s day care
        teacher, or a coworker) for something they did well. If you need ideas,{' '}
        <a target={'_blank'} href={m2Pdf3} rel='noreferrer'>
          click here
        </a>{' '}
        for ways to praise your child’s teacher, other caregivers, or family!
      </p>
    </>
  );
}
