import PageContent from './navigation/page-content/page-content';
import { Provider } from './context';
import './App.scss';
//initialize app

function App() {
  // this is the top level of the application.

  return (
    <div id='App'>
      <Provider>
        {/* provider will give the app reusable functions and a global state
          - context.js will hold these functions and state variables 
          - pagecontent is going to have the initial routing for the application 
      */}
        <PageContent />
      </Provider>
    </div>
  );
}

export default App;
