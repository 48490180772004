import React from 'react';
import './image.scss';
const Image = ({ number }) => {
  return (
    <div className='image-wrap'>
      <img
        src={
          require(`../../../assets/images/stock-images/stock${number}.jpg`)
            .default
        }
        alt='Stock'
      />
    </div>
  );
};

export default Image;
